<script>
import UsersApi from '@/api/users'
import UsersForm from './UsersForm'

export default {
  extends: UsersForm,

  mixins: [UsersApi],

  data() {
    return {
      dangerButtonText: 'Сбросить пароль',
      isNew: false
    }
  },

  created() {
    this.getUser(this.$route.params.id).then(response => {
      this.form = {
        ...response,
        district_ids: response.district_ids || []
      }
    })
  },

  computed: {
    warningButtonText() {
      return this.form.disabled_at ? 'Разблокировать': 'Заблокировать'
    }
  },

  methods: {
    handleFormSubmit() {
      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      const formData = this.beforeSubmit()

      if (!formData) {
        return
      }

      this.isSubmit = true

      this.updateUser(this.form.id, formData)
        .then(() => {
          window.snackbar('Пользователь успешно изменен', 'info')
          this.$router.push({ name: 'users' })
        })
        .finally(() => { this.afterSubmit() })
    },

    handleBlockUserClick() {
      if (this.form.disabled_at) {
        this.changeUserStatus()
        return
      }

      this.confirmTitle = 'Заблокировать пользователя?'
      // eslint-disable-next-line max-len
      this.confirmText = 'Данное действие приведет к блокировке пользователя и он больше не сможет пользоваться административной панелью. Вы уверены?'
      this.confirmCallback = this.changeUserStatus

      this.isShowConfirmDialog = true
    },

    changeUserStatus() {
      this.isSubmit = true

      this.userChangeStatus(this.form.id)
        .then(() => {
          window.snackbar( `Пользователь ${this.form.disabled_at ? 'разблокирован' : 'заблокирован' }`, 'info')
          this.form.disabled_at = this.form.disabled_at ? null : new Date()
        })
        .finally(() => { this.isSubmit = false })
    },

    handleResetPasswordClick() {
      this.confirmTitle = 'Сбросить пароль?'
      this.confirmText = 'Данное действие приведет к сбросу пароля к стандартному. Вы уверены?'
      this.confirmCallback = this.resetUserPassword

      this.isShowConfirmDialog = true
    },

    resetUserPassword() {
      this.isSubmit = true

      this.resetPassword(this.form.id)
        .then(() => {
          window.snackbar( 'Пароль успешно сброшен', 'info')
        })
        .finally(() => { this.isSubmit = false })
    }
  }
}
</script>
